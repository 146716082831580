<template>
    <div class="p-grid" v-if="Object.keys(keywordDetails).length !== 0">
        <Toast />
        <!-- <div class="">
            <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-flex-column p-flex-lg-row">
                <div class="p-mb-lg-0"></div>
                <div>
                    <Button class="p-button-primary p-mr-2">저장</Button>
                </div>
            </div>
        </div> -->
        <div class="p-col-12">
            <div class="buttonsBox">
                <Button class="p-button-primary p-mr-2" @click="saveAll">저장</Button>
            </div>
            <div class="card p-fluid p-mt-4">
                <h4>
                    <strong>컴포넌트</strong>
                </h4>

                <div class="p-field p-grid">
                    <label for="name3" class="p-col-8 p-mb-2 p-md-2 p-mb-md-0">타이틀</label>
                    <div class="p-col-12 p-md-10">
                        <InputText v-model="keywordDetails.title" maxlength="20" type="text" placeholder="타이틀을 입력해 주세요." />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="email3" class="p-col-8 p-mb-2 p-md-2 p-mb-md-0">서브 타이틀</label>
                    <div class="p-col-12 p-md-10">
                        <InputText v-model="keywordDetails.sub_title" maxlength="30" type="text" placeholder="서브 타이틀을 입력해 주세요." />
                    </div>
                </div>
                <!-- <div class="p-field p-grid">
                    <label for="email3" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">툴팁</label>
                    <div class="p-md-10">
                        <InputSwitch v-model="keywordDetails.show_tooltip" />
                    </div>
                </div>
                <div class="p-field p-grid" v-if="keywordDetails.show_tooltip">
                    <label for="name3" class="p-col-8 p-mb-2 p-md-2 p-mb-md-0">툴팁 타이틀</label>
                    <div class="p-col-12 p-md-10">
                        <InputText v-model="keywordDetails.tooltip_title" type="text" placeholder="툴팁 타이틀을 입력해 주세요."/>
                    </div>
                </div>
                <div class="p-field p-grid" v-if="keywordDetails.show_tooltip">
                    <label for="email3" class="p-col-8 p-mb-2 p-md-2 p-mb-md-0">툴팁 내용</label>
                    <div class="p-col-12 p-md-10">
                        <InputText v-model="keywordDetails.tooltip_sub_title" type="text" placeholder="툴팁에 노출 할 내용을 입력해 주세요."/>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="p-col-12">
            <div class="card" v-for="(item, i) in keywordDetails.groups" :key="i">
                <!--  -->
                <div class="p-d-flex p-jc-between p-mb-2">
                    <h5 class="p-sortable">그룹 <i class="pi pi-arrow-up" @click="orderring('rowup', i)"></i>
                        <i class="pi pi-arrow-down" @click="orderring('rowdown', i)"></i>
                    </h5>
                    <Button label="그룹 삭제" class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"
                        @click="openModal('delete', i)" />
                </div>
                <!-- <div class="p-d-flex p-jc-between p-mb-2">
                    <h5>피신고자 목록</h5>
                </div> -->
                <!-- {{ item.chatrooms.length }} -->


                <div class="p-field p-grid">

                    <label for="email3" class="p-col-4 p-mb-2 p-md-2 p-mb-md-0">타이틀</label>
                    <div class="p-col-12 p-md-10">
                        <InputText class="inputs" maxlength="10" placeholder="채팅방 그룹 타이틀을 입력해 주세요." type="text"
                            v-model="item.group_title" />
                    </div>
                </div>
                <div class="p-d-flex p-jc-between p-mb-2">
                    <div></div>

                    <div>
                        <Button icon="pi pi-plus" label="채팅방 추가" class="p-button-text p-mr-2 p-mb-2 underline"
                            @click="openModal('chatCount', i)" />
                        <Button icon="pi pi-minus" label="채팅방 삭제" class="p-button-danger p-button-text p-mr-2 p-mb-2"
                            :disabled="item.selectedRows.length == 0" @click="openModal('bulkDelete', i)" />
                    </div>
                </div>

                <!-- {{ item.group_id }}
                <pre>{{ item.chatrooms }}</pre> -->

                <div class="not-cursur-table">
                    <DataTable v-model:selection="item.selectedRows" :value="item.chatrooms" :paginator="false" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="false"
                    :loading="loading" :filters="filters" responsiveLayout="scroll" >
                        <!-- v-model:selection="selected" 
                         selectionMode="checkbox"
                        -->
                        <ConfirmDialog group="dialog" />

                        <template #empty> 데이터가 없습니다. </template>
                        <template #loading> 데이터를 로드 중입니다. 기다리다. </template>

                        <column selectionMode="multiple" style="width: 16px; text-align: center" />
                       

                        <Column header="순서" style="min-width: 7rem" field="order">
                            <template #body="{ data }">
                                <span class="p-column-title">Order</span>
                                <i class="pi pi-arrow-up cursors" @click="orderingChat('rowup', i, data)"></i>
                                <i class="pi pi-arrow-down cursors" @click="orderingChat('rowdown', i, data)"></i>
                            </template>
                        </Column>

                        <Column header="채팅방 이름" style="min-width: 12rem" field="room_name">
                            <template #body="{ data }">
                                <router-link :to="'/ChatroomManagement/ChatroomDetails/' + data.room_id" class="highlight"
                                style="color: #69707a">
                                {{ data.room_name }}
                            </router-link>
                            </template>
                        </Column>

                        <Column header="방장" style="min-width: 16rem" field="owner">
                            <template #body="{ data }">
                                <router-link :to="'/UserManagement/UserDetailsview/' + data.owner_id" class="highlight"
                                style="color: #69707a">
                                {{ data.owner }}
                            </router-link>
                            </template>
                        </Column>
                        <Column header="채팅방유형" style="min-width: 16rem" field="room_type">
                            <template #body="{ data }">
                                <span class="p-column-title">chatroom Type</span>
                                <span v-if="data.room_type === 'package'">패키지</span>
                                <span v-if="data.room_type !== 'package'">{{ $t(data.room_type) }}</span>
                            </template>
                        </Column>
                        <Column header="지역" style="min-width: 16rem" field="address">
                            <template #body="{ data }">
                                <span class="p-column-title">Region</span>
                                {{ data.address }}
                            </template>
                        </Column>
                        <Column header="참여자수" style="min-width: 7rem" field="room_users_count">
                            <template #body="{ data }">
                                <span class="p-column-title">Number of Participants</span>
                                {{ data.room_users_count }}
                            </template>
                        </Column>
                        <Column header="비밀방 여부" style="min-width: 9rem" field="is_passcode_protected">
                            <template #body="{ data }">
                                <span class="p-column-title">secret room or Not</span>
                                <p v-if="data.is_passcode_protected == 0" id="round">No</p>
                                <p v-else id="round">Yes</p>
                            </template>
                        </Column>
                        <Column header="마지막 활동 일시" style="min-width: 16rem" field="last_activity_date">
                            <template #body="{ data }">
                                <span class="p-column-title">Date of last Activity</span>
                                {{ formatDate(data.last_activity_date) == null ? '-' :
        formatDate(data.last_activity_date) }}
                            </template>
                        </Column>

                        <Column header="상태" style="min-width: 7rem" field="status">
                            <template #body="{ data }">
                                <span class="p-column-title">Situation</span>
                                {{ $t(data.status) }}
                            </template>
                        </Column>
                        <Column header="블릿" style="min-width: 10rem" field="reportDate">
                            <template #body="{ data }">
                                <span class="p-column-title">Bullet</span>
                                <div v-if="data.edited_chatroom_id !== ''" style="display: flex; gap: 5px;">
                                    <Button label="help" class="iconBtn grey" @click="openBullet(data.id, i, data)"><i
                                            class="pi pi-pencil"></i></Button>
                                    <Button v-if="data.bullet_setting_available === 1" label="help"
                                        @click="openModal('bulletDelete', i, data.id)" class="iconBtn red"><i
                                            class="pi pi-trash"></i></Button>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex p-jc-center p-mb-2">
                    <div>
                        <!-- <h5 class="p-sortable"><span style="padding-bottom: 15px;">Add group</span> <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 " /></h5> -->
                        <Button @click="addGroup" label="그룹 추가" icon="pi pi-plus" iconPos="right"
                            class="p-button-text p-mr-2 p-mb-2"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ConfirmationModalProfessionalChatroom :buttonType="buttonType" :showModal="showConfirmModal" @check="checkFunction"
        @close="showConfirmModal = false" />
    <ProfessionalBulletModal :showModal="showBulletModal" :id="chatRoomId" :bullet="chatroomBulletData" @close="showBulletModal = false"
        @bulletSaved="bulletSaved" />
    <ProfessionalChatroomSelectionModal :groupId="groupId" :selectedChat="selectedChatRoom"
        :showModal="openChatroomModal" @close="closeChatRoomselectionModal($event)" />
        <ErrorPopup :showModal="showErrorModal" @accept="showErrorModal = false, errorMessages = []" :messages="errorMessages" />
</template>

<script>
import ProfessionalChatroomSelectionModal from '@/components/modal/ProfessionalChatroomSelectionModal.vue';
import SubmainManagementService from '../../service/API/SubmainManagementService';
import ConfirmationModalProfessionalChatroom from '@/components/modal/ConfirmationModalProfessionalChatroom.vue';
import ProfessionalBulletModal from '@/components/modal/ProfessionalBulletModal.vue';
import ErrorPopup from '../../components/modal/ErrorPopup.vue';
import moment from 'moment';
export default {
    components: {
        ConfirmationModalProfessionalChatroom,
        ProfessionalBulletModal,
        ProfessionalChatroomSelectionModal,
        ErrorPopup,
    },
    data() {
        return {
            showErrorModal: false,
            errorMessages: [],
            keywordDetails: {},
            chatRoomId: '',
            title: '',
            sub_title: '',
            show_tooltip: false,
            tooltip_title: '',
            tooltip_sub_title: '',
            selectedChatRoom: [],
            selectedData: [],
            showConfirmModal: false,
            buttonType: '',
            showBulletModal: false,
            group: [],
            groupIndex: null,
            openChatroomModal: false,
            groupId: 0,
            chatIndex: null,
            chatroomBulletData: {},
        };
    },
    created() {
        this.submainManaggementService = new SubmainManagementService();
    },
    mounted() {
        this.chatroomDetails();
    },
    watch: {},
    methods: {
        openModal(type, index = null, chatId = null) {
            if (index !== null && type === 'delete') {
                this.groupIndex = index;
                this.showConfirmModal = true;
            } else if (index !== null && type === 'chatCount') {
                this.keywordDetails.groups[index].chatrooms.length === 9 ? this.showConfirmModal = true : this.openChatroomModal = true;
                let tempData = this.keywordDetails.groups[index].chatrooms;
                this.selectedChatRoom = tempData.map((item) => item.room_id);
                console.log(this.selectedChatRoom);
                this.groupIndex = index;
                this.groupId = this.keywordDetails.groups[index].group_id;
                document.body.classList.add("modal-open");
            } else if (type === 'bulkDelete') {
                this.groupIndex = index;
                this.showConfirmModal = true;
            } else if (type === 'bulletDelete') {
                this.groupIndex = index;
                this.chatRoomId = chatId;
                this.showConfirmModal = true;
            }
            this.buttonType = type;
        },
        checkFunction(type) {
            if (type === 'delete') {
                this.deleteGroup();
            } else if (type === 'bulkDelete') {
                this.bulkDelete();
            } else if (type === 'chatCount') {
                this.showConfirmModal = false;
                document.body.classList.remove("modal-open");
            } else if (type === 'bulletDelete') {
                this.deleteBullet();
            }
        },
        deleteBullet() {
            try {
                // let response = this.submainManaggementService.chatroomBulletDelete({ component_group_chatroom_id: this.chatRoomId });
                // console.log(response);
                let findChatIndex = this.keywordDetails.groups[this.groupIndex].chatrooms.findIndex((item) => item.id === this.chatRoomId);
                // console.log(this.keywordDetails.groups[this.groupIndex].chatrooms[findChatIndex]);
                this.keywordDetails.groups[this.groupIndex].chatrooms[findChatIndex].bullet_setting_available = 0;
                this.keywordDetails.groups[this.groupIndex].chatrooms[findChatIndex].bullet.setting_available = 0;
                this.showConfirmModal = false;
                this.$toast.add({ severity: 'success', summary: 'Success', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                // setTimeout(() => {
                //     this.chatroomDetails();
                // }, 1000);
                
            } catch (error) {
                console.log(error);
                this.showConfirmModal = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: '문제가 발생했습니다.', life: 3000 });
            }


        },
        deleteGroup() {
            try {
                //let response = this.submainManaggementService.groupDelete({ group_id: this.keywordDetails.groups[this.groupIndex].group_id });
                this.keywordDetails.groups.splice(this.groupIndex, 1);
                //console.log(response);
                this.showConfirmModal = false;
                this.$toast.add({ severity: 'success', summary: 'Success', detail: '성공적으로 삭제되었습니다.', life: 3000 });
            } catch (error) {
                console.log(error);
                this.showConfirmModal = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'S문제가 발생했습니다.', life: 3000 });
            }
        },
        bulkDelete() {
            let idArray = [];
            this.keywordDetails.groups[this.groupIndex].selectedRows.forEach((item) => {
                idArray.push(item.room_id);
            });
            try {
                // let response = this.submainManaggementService.chatroomDelete({ chatroom_ids: idArray, group_id: this.keywordDetails.groups[this.groupIndex].group_id });
                // console.log(response);
                this.keywordDetails.groups[this.groupIndex].chatrooms = this.keywordDetails.groups[this.groupIndex].chatrooms.filter((item) => !idArray.includes(item.room_id));
                this.keywordDetails.groups[this.groupIndex].selectedRows = [];
                this.showConfirmModal = false;
                this.$toast.add({ severity: 'success', summary: 'Success', detail: '성공적으로 삭제되었습니다.', life: 3000 });
            } catch (error) {
                console.log(error);
                this.showConfirmModal = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: '문제가 발생했습니다.', life: 3000 });
            }
        },
        openChatroom() {
            this.openChatroomModal = true;
        },
        openBullet(chatId, groupIndexId, data) {
            console.log('chatRoomId', chatId);
            this.chatIndex = this.keywordDetails.groups[groupIndexId].chatrooms.indexOf(data)
            this.chatroomBulletData = this.keywordDetails.groups[groupIndexId].chatrooms[this.chatIndex].bullet
            //console.log('this.chatIndex', this.chatIndex);
            this.chatRoomId = chatId;
            this.groupIndex = groupIndexId;
            this.showBulletModal = true;
        },
        getStatus(values) {
            return values === 'active' ? true : false;
        },

        async addGroup() {
            if (this.keywordDetails.groups.length >= 10) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: '그룹은 최대 10개만 생성할 수 있습니다.', life: 3000 });
                return;
            } else {
                try {
                    let data = {
                        type: 'professional',
                    };
                    let response = await this.submainManaggementService.groupAdd(data);
                    console.log(response);

                    this.keywordDetails.groups.push({
                        id: response.data.group_id,
                        group_id: response.data.group_id,
                        group_status: response.data.group_status,
                        group_title: '',//'Group title'+ (this.keywordDetails.groups.length + 1),
                        selectedRows: [],
                        chatrooms: [],
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        },

        orderring(text, index) {
            let ids;
            if (text === 'rowup') {
                if (index > 0) {
                    const temp = this.keywordDetails.groups[index - 1];
                    this.keywordDetails.groups.splice(index - 1, 1, this.keywordDetails.groups[index]);
                    this.keywordDetails.groups.splice(index, 1, temp);
                    ids = this.keywordDetails.groups.map((item) => item.group_id);
                    this.groupOrdering(ids);

                }
            } else if (text === 'rowdown') {
                if (index < this.keywordDetails.groups.length - 1) {
                    const temp = this.keywordDetails.groups[index + 1];
                    this.keywordDetails.groups.splice(index + 1, 1, this.keywordDetails.groups[index]);
                    this.keywordDetails.groups.splice(index, 1, temp);
                    ids = this.keywordDetails.groups.map((item) => item.group_id);
                    this.groupOrdering(ids);

                }
            }

        },

        async groupOrdering(ids) {
            const data = {
                group_ids: ids,
            };
            try {
                let response = this.submainManaggementService.groupOrdering(data);
                console.log(response);
                this.$toast.add({ severity: 'success', summary: 'Success', detail: '순서가 성공적으로 저장되었습니다.', life: 3000 });
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: 'error', summary: 'Error', detail: '문제가 발생했습니다.', life: 3000 });
                this.chatroomDetails();
            }
        },

        orderingChat(text, index, data) {
            let ids;
            if (text === 'rowup') {
                const rowIndex = this.keywordDetails.groups[index].chatrooms.indexOf(data)
                if (rowIndex > 0) {
                    const temp = this.keywordDetails.groups[index].chatrooms[rowIndex - 1];
                    this.keywordDetails.groups[index].chatrooms.splice(rowIndex - 1, 1, this.keywordDetails.groups[index].chatrooms[rowIndex]);
                    this.keywordDetails.groups[index].chatrooms.splice(rowIndex, 1, temp);
                    ids = this.keywordDetails.groups[index].chatrooms.map((item) => item.room_id);
                    this.chatOrdering(ids, index);
                }
            } else if (text === 'rowdown') {
                const rowIndex = this.keywordDetails.groups[index].chatrooms.indexOf(data)
                if (rowIndex < this.keywordDetails.groups[index].chatrooms.length - 1) {
                    const temp = this.keywordDetails.groups[index].chatrooms[rowIndex + 1];
                    this.keywordDetails.groups[index].chatrooms.splice(rowIndex + 1, 1, this.keywordDetails.groups[index].chatrooms[rowIndex]);
                    this.keywordDetails.groups[index].chatrooms.splice(rowIndex, 1, temp);
                    ids = this.keywordDetails.groups[index].chatrooms.map((item) => item.room_id);
                    this.chatOrdering(ids, index);
                }
            }
        },

        async chatOrdering(ids, index) {
            const tempData = {
                chatroom_ids: ids,
                group_id: this.keywordDetails.groups[index].group_id,
            };
            try {
                let response = this.submainManaggementService.chatroomOrdering(tempData);
                console.log(response);
                this.$toast.add({ severity: 'success', summary: 'Success', detail: '순서가 성공적으로 저장되었습니다.', life: 3000 });
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: 'error', summary: 'Error', detail: '문제가 발생했습니다.', life: 3000 });
                this.chatroomDetails();
            }
        },

        closeChatRoomselectionModal(items) {
            let selectedChatrooms = items
            selectedChatrooms.map((item) => {
                item.chatroom_id = item.id,
                    item.room_id = item.id,
                    item.edited_chatroom_id = '',
                    item.room_selected = true,
                    item.order_no = this.keywordDetails.groups[this.groupIndex].chatrooms.length + 1,
                    item.bullet_setting_available = 0
            });
            console.log(selectedChatrooms);
            selectedChatrooms.forEach((item) => {
                this.keywordDetails.groups[this.groupIndex].chatrooms.push(item);
            });

            this.keywordDetails.groups[this.groupIndex].chatrooms = Array.from(new Set(this.keywordDetails.groups[this.groupIndex].chatrooms.map(item => item.room_id)))
                .map(room_id => {
                    return this.keywordDetails.groups[this.groupIndex].chatrooms.find(item => item.room_id === room_id);
                });
            // alert('hhhhh');
            console.log(this.keywordDetails.groups[this.groupIndex].chatrooms);
            // alert('hhhhh');

            this.openChatroomModal = false;
            document.body.classList.remove("modal-open");
        },
        bulletSaved(data) {
            this.keywordDetails.groups[this.groupIndex].chatrooms[this.chatIndex].bullet = data;
            this.keywordDetails.groups[this.groupIndex].chatrooms[this.chatIndex].bullet_setting_available = 1;
            this.showBulletModal = false;
            this.$toast.add({ severity: 'success', summary: 'Successfull', detail: '블릿이  성공적으로 저장되었습니다.', life: 3000 });
           // this.chatroomDetails();
        },

        async saveAll() {
            if(this.keywordDetails.title === '' || this.keywordDetails.sub_title === '' || 
            this.keywordDetails.groups.length === 0 || (this.keywordDetails.groups.length > 0 && this.keywordDetails.groups.some((item) => item.group_title === '')) || (this.keywordDetails.groups.length > 0 && this.keywordDetails.groups.some((item) => item.chatrooms.length === 0))) {
                if(this.keywordDetails.title === '') {
                    this.errorMessages.push('컴포넌트 타이틀을 입력해 주세요.');
                } if(this.keywordDetails.sub_title === '') {
                    this.errorMessages.push('컴포넌트 서브 타이틀을 입력해 주세요.');
                } if(this.keywordDetails.groups.length === 0) {
                    this.errorMessages.push('컴포넌트에 그룹을 1개 이상 추가해 주세요.');
                } if (this.keywordDetails.groups.length > 0) {
                        for (let i = 0; i < this.keywordDetails.groups.length; i++) {
                        if(this.keywordDetails.groups[i].group_title === '') {
                            this.errorMessages.push('그룹 타이틀을 입력해 주세요.');
                        }
                        if(this.keywordDetails.groups[i].chatrooms.length === 0) {
                            this.errorMessages.push('추가된 그룹에 채팅방을 1개 이상 추가해 주세요.');
                        }
                    }
                }
                this.showErrorModal = true;
            } else {
            let tempGroup = this.keywordDetails.groups.map((item) => {
                //console.log('item', item)
                return {
                    group_title: item.group_title,
                    edited_group_id: item.group_id,
                    chatrooms: item.chatrooms.map((chatroom) => {
                        return {
                            chatroom_id: chatroom.chatroom_id,
                            edited_chatroom_id: chatroom.edited_chatroom_id,
                            bullet: chatroom.bullet,
                        };
                    }),
                };
            });

            const titleIsEmpty = tempGroup.some(obj => {
                return Object.values(obj).some(value => value === '');
            });

            if (titleIsEmpty) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: '그룹 제목을 입력하세요', life: 3000 });

            } else {
                let data = {
                    title: this.keywordDetails.title,
                    sub_title: this.keywordDetails.sub_title,
                    // show_tooltip: this.keywordDetails.show_tooltip ? 'yes' : 'no',
                    // tooltip_title: this.keywordDetails.tooltip_title,
                    // tooltip_sub_title: this.keywordDetails.tooltip_sub_title,
                    groups: tempGroup,
                };
                try {
                    let response = await this.submainManaggementService.professionalChatroomSave(data);

                    console.log(response);
                    this.chatroomDetails();
                    // localStorage.removeItem('selectedChatrooms')
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: '저장 완료', life: 3000 });
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: '문제가 발생했습니다.', life: 3000 });
                }
            }
        }

        },


        async chatroomDetails() {
            try {
                let response = await this.submainManaggementService.professionalChatroomDetails();
                this.keywordDetails = response.data;
                this.keywordDetails.show_tooltip = this.keywordDetails.show_tooltip === 'active' ? true : false;
                console.log(this.keywordDetails);
                this.keywordDetails.groups.map((item) => {
                    item.id = item.group_id;
                    item.selectedRows = [];
                    item.chatrooms.map((chatroom) => {
                        chatroom.chatroom_id = chatroom.room_id;
                        chatroom.edited_chatroom_id = chatroom.id;
                    });
                });
                //this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Saved successfully', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: '문제가 발생했습니다.', life: 3000 });
            }
        },
       
        formatDate(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        
    },
};
</script>

<style lang="scss" scoped>
.p-datatable-tbody {
    .p-button {
        white-space: nowrap;
    }
}

.p-fluid {
    .p-button {
        width: auto;
    }
}

p#round {
    border-radius: 12px;
    border: 1px solid black;
    width: 52px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.highlight:hover {
    border-bottom: 3px solid Blue;
}

.inputs {
    width: 100%;
}

.buttonsBox {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;

}

.iconBtn {
    padding: 6px;
    background: none;
    border: none;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 100%;
}
.iconBtn.grey{
    color: #ccc;
}

.iconBtn.red {
    color: red;
}

.cursors {
	cursor: pointer;
}
</style>
