<template>
    <div v-if="showModal" class="modal-overlay">
        <!-- <div class="modal-content"> -->
        <div class="new-table-modal-content">
            <Toast />
            <div class="modal-heads" @click="closeModal">
                <h4>채팅방 추가</h4>
                <span><i class="pi pi-times" style="font-size: 1.5rem"></i></span>
            </div>
            
            <div class="innre-content p-mt-4">
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="ChatroomName">채팅방 이름</label>
                        <InputText id="ChatroomName" type="text" :value="search_key" v-model="search_key"
                            placeholder="채팅방 이름으로 검색" @keyup="resetsearchdata" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="ChatroomType">채팅방 유형</label>
                        <Dropdown :value="group_type" v-model="group_type" :options="dropdownchatroomtype"
                            optionValue="code" optionLabel="name" placeholder="전체 " />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="Region">지역</label>
                        <AutoComplete placeholder="채팅방 지역으로 검색" :dropdown="true" :multiple="false"
                            v-model="selectedAutoValue" :value="selectedAutoValue?.id" :suggestions="autoFilteredValue"
                            @complete="searchCountry($event)" field="name" />
                    </div>

                    <div class="p-field p-col-12 p-md-3" style="display: grid">
                        <label for="SecretRoom">비밀방 여부</label>
                        <InputSwitch trueValue="yes" v-model="is_secret" :value="is_secret" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="User">사용자</label>

                        <InputText id="Owner" v-model="user" :value="user" type="text" placeholder="사용자 닉네임으로 검색" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="Status">상태</label>
                        <Dropdown v-model="status" :options="dropdownstatus" :value="status" optionValue="code"
                            optionLabel="name" placeholder="전체 " />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">생성일</label>
                        <Calendar inputId="range" v-model="date_range" :value="date_range" dateFormat="yy/mm/dd" selectionMode="range"
                            :manualInput="false" placeholder="YYYY/MM/DD - YYYY/MM/DD"></Calendar>
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="Sort by">정렬 기준</label>
                        <Dropdown v-model="search_type" :value="search_type" :options="dropdownsortby"
                            optionValue="code" optionLabel="name" placeholder="전체 " />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">마지막 활동일</label>
                        <Calendar inputId="range" v-model="lastdate_range" :value="lastdate_range" dateFormat="yy/mm/dd" selectionMode="range"
                            :manualInput="false" placeholder="YYYY/MM/DD - YYYY/MM/DD">
                        </Calendar>
                    </div>
                </div>
                <!-- button area -->
                <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-4 p-flex-column p-flex-lg-row">
                    <div class="p-mb-4 p-mb-lg-0">
                        <Button :label="$t('button.today')" icon="pi pi-calendar" iconPos="left"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="today"></Button>
                        <Button :label="$t('button.lastWeek')" icon="pi pi-calendar" iconPos="left"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastweek"></Button>
                        <Button :label="$t('button.lastMonth')" icon="pi pi-calendar" iconPos="left"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastmonth"></Button>
                        <Button :label="$t('button.last6Months')" icon="pi pi-calendar" iconPos="left"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastsixmonth"></Button>
                        <!-- <Button :label="$t('button.lastYear')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastyear"></Button> -->
                    </div>
                    <div>
                        <Button label="검색" icon="pi pi-search" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2"
                            @click="searchchatroom"></Button>
                        <Button label="초기화" icon="pi pi-replay" iconPos="left"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"
                            @click="resetchatroom"></Button>
                    </div>
                </div>
            </div>

            <div class="innre-content">
                <div class="p-d-flex p-jc-between p-mb-2">
                    <div>
                        <h5>채팅방 목록</h5>
                    </div>
                    <div></div>
                </div>
                <!-- <pre>{{ selectedChat }} </pre> -->

                <!-- <pre>{{ selectedPageWiseData }}</pre> -->

                <!-- <pre>{{ customer1 }}</pre> -->
          

                <DataTable :value="customer1" :paginator="false" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading1" :filters="filters" responsiveLayout="scroll">
                    <!-- v-model:selection="selected" -->
                    <ConfirmDialog group="dialog" />

                    <template #empty> 데이터가 없습니다. </template>
                    <template #loading> 데이터를 로드 중입니다. 기다리다. </template>

                    <!-- <column selectionMode="multiple" style="width: 16px; text-align: center" /> -->
                    <Column field="slNO" :header="$t('#')" :sortable="true">
                        <template #body="{ index }">
                            <span class="p-column-title">slNo</span>

                            {{ pagination.current_page * pagination.pagination_limit -
        pagination.pagination_limit + (index + 1) }}
                        </template>
                    </Column>
                    <Column header="채팅방 이름" style="min-width: 12rem" field="room_name" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Chatroom Name</span>
                            <!-- <router-link :to="'/ChatroomManagement/ChatroomDetails/' + data.id" class="highlight"
                                style="color: #69707a"> -->
                                {{ data.room_name }}
                            <!-- </router-link> -->
                        </template>
                    </Column>

                    <Column header="방장" style="min-width: 12rem" field="owner" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Owner</span>
                            <!-- <router-link :to="'/UserManagement/UserDetailsview/' + data.owner_user_id" class="highlight"
                                style="color: #69707a"> -->
                                {{ data.owner }}
                            <!-- </router-link> -->
                        </template>
                    </Column>

                    <Column header="채팅방 유형" style="min-width: 12rem" field="room_type" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Chatroom Type</span>
                            <span v-if="data.room_type === 'package'">패키지</span>
                            <span v-if="data.room_type !== 'package'">{{ $t(data.room_type) }}</span>
                            <!-- {{ $t(data.room_type) }} -->
                        </template>
                    </Column>

                    <Column header="지역" style="min-width: 12rem" field="country" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Region</span>
                            {{ data.city }}
                        </template>
                    </Column>

                    <Column header="참여자수" style="min-width: 6rem" field="room_users_count" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Number of Participants</span>
                            {{ data.room_users_count }}
                        </template>
                    </Column>

                    <Column header="비밀방 여부" style="min-width: 10rem" field="room_passcode" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Secret Room</span>
                            <p v-if="data.is_passcode_protected == null || data.is_passcode_protected == '' || data.is_passcode_protected == 0" id="round">No</p>
                            <p v-if="data.is_passcode_protected == 1" id="round">Yes</p>
                        </template>
                    </Column>

                    <Column header="생성일시" style="min-width: 12rem" field="created_date" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Created Date</span>
                            {{ formatDate(data.created_date) }}
                        </template>
                    </Column>

                    <Column header="마지막 활동 일시" style="min-width: 12rem" field="last_activity_date" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Last Activity</span>
                            {{ formatDate(data.last_activity_date) == null ? '-' :
        formatDate(data.last_activity_date) }}
                        </template>
                    </Column>

                    <Column header="상태" style="min-width: 8rem" field="status" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Status</span>
                            {{ $t(data.status) }}
                        </template>
                    </Column>

                    <Column header="">
                        <template #body="{ data }">
                            <span class="p-column-title">Action</span>
                            <p style="display: none">{{ data.room_selected }}</p>
                            <div style="display: flex">
                                <Button v-if="data.room_selected === false"  label="help" @click="selectedData(data)"
                                        class="p-button2">선택</Button>
                                <Button v-if="data.room_selected === true" label="help"
                                class="p-button2 darkBtn">선택됨</Button> 
                                <!-- amit da -->

                            </div>
                        </template>
                    </Column>
                </DataTable>

                <div class="p-paginator p-component p-paginator-bottom">
                    <button @click="paginate(1)" :class="{ 'p-disabled': pagination.current_page === 1 }"
                        class="p-paginator-first p-paginator-element p-link" type="button"
                        :disabled="pagination.current_page === 1">
                        <span class="p-paginator-icon pi pi-angle-double-left"></span>
                        <span class="p-ink"></span>
                    </button>
                    <button @click="paginate(pagination.current_page - 1)"
                        :class="{ 'p-disabled': pagination.current_page === 1 }"
                        class="p-paginator-next p-paginator-element p-link" type="button"
                        :disabled="pagination.current_page === 1">
                        <span class="p-paginator-icon pi pi-angle-left"></span>
                        <span class="p-ink"></span>
                    </button>
                    <span class="p-paginator-pages">
                        <button
                            v-for="(page, index) in get_paginator_page(pagination.total_pages, pagination.current_page)"
                            :key="index" @click="paginate(page)" class="p-paginator-page p-paginator-element p-link"
                            :class="{ 'p-highlight': page === pagination.current_page }" type="button">
                            {{ page }}<span class="p-ink"></span>
                        </button>
                    </span>
                    <button @click="paginate(pagination.current_page + 1)"
                        :class="{ 'p-disabled': pagination.current_page === pagination.total_pages }"
                        class="p-paginator-next p-paginator-element p-link" type="button"
                        :disabled="pagination.current_page === pagination.total_pages">
                        <span class="p-paginator-icon pi pi-angle-right"></span>
                        <span class="p-ink"></span>
                    </button>
                    <button @click="paginate(pagination.total_pages)"
                        :class="{ 'p-disabled': pagination.current_page === pagination.total_pages }"
                        :disabled="pagination.current_page === pagination.total_pages"
                        class="p-paginator-last p-paginator-element p-link" type="button">
                        <span class="p-paginator-icon pi pi-angle-double-right"></span>
                        <span class="p-ink"></span>
                    </button>
                </div>

                <div style="text-align: right; margin-top: -35px">
                    <label for="order">
                        {{ pagination.total_records }}개 중 {{ pagination.current_page *
        pagination.pagination_limit -
        pagination.pagination_limit + 1 }} -
                        {{ pagination.total_pages === pagination.current_page ? pagination.total_records :
        pagination.current_page *
                        pagination.pagination_limit - pagination.pagination_limit + 10 }}
                    </label>
                </div>


            </div>


        </div>
    </div>

</template>

<script>

import ChatroomService from '../../service/API/ChatroomService';
import SubmainManagementService from '../../service/API/SubmainManagementService';
import moment from 'moment';
import utils from '@/service/utils';
export default {
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        selectedChat: {
            type: Array,
            required: true,
        },
        groupId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            selectedChatrooms: [],
            selectedPageWiseData: [],
            render: true,
            display: false,
            position: 'center',
            visibleLeft: false,
            visibleRight: false,
            visibleTop: false,
            visibleBottom: false,
            visibleFull: false,
            value: '',
            selectedAutoValue: { name: '' },
            autoFilteredValue: [],
            dropdownValues: '',
            dropdownValue: null,
            totalrecords: '',
            pagination: {
                current_page: 1,
                pagination_limit: 10,
                total_pages: 0,
                total_records: 0,
            },
            // complexvalue: [],
            statusTrue: true,
            statusFalse: false,
            owner_user_id: '',
            customer1: [],
            loading1: true,
            idFrozen: false,
            products: null,
            expandedRows: [],
            dropdownchatroomtype: [
                { name: '전체', code: 'all' },
                { name: '일반', code: 'general' },
                { name: '위치 기반', code: 'location' },
                { name: '패키지', code: 'package' },
            ],

            dropdownstatus: [
                { name: '전체', code: 'all' },
                { name: '활성', code: 'active' },
                { name: '비활성', code: 'deleted' },
            ],

            dropdownsortby: [
                { name: '인기도', code: 'noisy' },
                { name: '생성일 최신', code: 'hot' },
                { name: '생성일 오래된', code: 'location' },
            ],
            room_name: '',
            room_type: '',
            id: '',
            country: '',
            city: '',
            address: '',
            room_users_count: '',
            name: '',
            owner: '',
            created_date: '',

            last_activity_date: '',
            search_key: '',
            group_type: '',
            user: '',
            status: '',
            create_start_date: '',
            create_end_date: '',
            last_start_date: '',
            last_end_date: '',
            is_secret: '',
            date_range: [],
            lastdate_range: '',
            searchdate: '',
            searchenddate: '',
            searclasthdate: '',
            searchlastenddate: '',
            room_passcode: '',
            cities: '',
            search_type: '',
        };
    },
    customerService: null,
    productService: null,
    created() {
        this.chatroomService = new ChatroomService();
        this.submainManaggementService = new SubmainManagementService();
        this.storageData = localStorage.getItem('chatManagement') ? JSON.parse(localStorage.getItem('chatManagement')) : '';
        console.log(this.storageData, 'data');

        if (this.storageData != '') {
            this.search_key = this.storageData.search_key == undefined ? '' : this.storageData.search_key;
            this.group_type = this.storageData.group_type == undefined ? '' : this.storageData.group_type;
            this.user = this.storageData.user == undefined ? '' : this.storageData.user;
            this.status = this.storageData.status == undefined ? '' : this.storageData.status;
            this.create_start_date = this.storageData.create_start_date == undefined ? '' : this.storageData.create_start_date ? this.storageData.create_start_date : '';
            this.create_end_date = this.storageData.create_end_date == undefined ? '' : this.storageData.create_end_date ? this.storageData.create_end_date : '';
            this.last_start_date = this.storageData.last_start_date == undefined ? '' : this.storageData.last_start_date ? this.storageData.last_start_date : '';
            this.last_end_date = this.storageData.last_end_date == undefined ? '' : this.storageData.last_end_date ? this.storageData.last_end_date : '';
            this.is_secret = this.storageData.is_secret;
            this.search_type = this.storageData.search_type == undefined ? '' : this.storageData.search_type;
            this.cities = this.storageData.cities;
            this.selectedAutoValue.name = this.storageData.cityName == undefined ? '' : this.storageData.cityName;
            this.selectedAutoValue.id = this.storageData.cities;
            this.chatroomlistpagination = this.storageData.page;
            let dataRangeValOne = this.storageData.create_start_date ? new Date(this.storageData.create_start_date) : '';
            let dataRangeValTwo = this.storageData.create_end_date ? new Date(this.storageData.create_end_date) : '';
            this.date_range = [dataRangeValOne, dataRangeValTwo];
            let lastdataRangeValOne = this.storageData.last_start_date ? new Date(this.storageData.last_start_date) : '';
            let lastdataRangeValTwo = this.storageData.last_end_date ? new Date(this.storageData.last_end_date) : '';
            this.lastdate_range = [lastdataRangeValOne, lastdataRangeValTwo];
        }

        if (this.chatroomlistpagination == '' || this.chatroomlistpagination == null) {
            if (this.search_key || this.group_type || this.user || this.status || this.create_start_date || this.create_end_date || this.last_start_date || this.last_end_date || this.is_secret || this.search_type || this.cities) {
                const searchLocalParams = {
                    page: this.pagination.current_page,
                    record_count: this.pagination.pagination_limit,
                    search_key: this.search_key == undefined ? '' : this.search_key,
                    group_type: this.group_type == undefined ? '' : this.group_type,
                    user: this.user == undefined ? '' : this.user,
                    status: this.status == undefined ? '' : this.status,
                    create_start_date: this.create_start_date == undefined || NaN ? '' : this.create_start_date ? this.create_start_date : '',
                    create_end_date: this.create_end_date == undefined || NaN ? '' : this.create_end_date ? this.create_end_date : '',
                    last_start_date: this.last_start_date == undefined || NaN ? '' : this.last_start_date ? this.last_start_date : '',
                    last_end_date: this.last_end_date == undefined || NaN ? '' : this.last_end_date ? this.last_end_date : '',
                    is_secret: this.is_secret === 'yes' ? true : '',
                    search_type: this.search_type == undefined ? '' : this.search_type,
                    cities: this.selectedAutoValue?.id == undefined ? '' : this.selectedAutoValue?.id,
                };

                this.chatroomlist(searchLocalParams);
            } else {
                this.chatroomlist({ record_count: this.pagination.pagination_limit, page: this.pagination.current_page });
            }
        } else {
            const searchLocalParams = {
                page: this.chatroomlistpagination,
                record_count: this.pagination.pagination_limit,
                search_key: this.search_key == undefined ? '' : this.search_key,
                group_type: this.group_type == undefined ? '' : this.group_type,
                user: this.user == undefined ? '' : this.user,
                status: this.status == undefined ? '' : this.status,
                create_start_date: this.create_start_date == undefined || NaN ? '' : this.create_start_date ? this.create_start_date : '',
                create_end_date: this.create_end_date == undefined || NaN ? '' : this.create_end_date ? this.create_end_date : '',
                last_start_date: this.last_start_date == undefined || NaN ? '' : this.last_start_date ? this.last_start_date : '',
                last_end_date: this.last_end_date == undefined || NaN ? '' : this.last_end_date ? this.last_end_date : '',
                is_secret: this.is_secret === 'yes' ? true : '',
                search_type: this.search_type == undefined ? '' : this.search_type,
                cities: this.selectedAutoValue?.id == undefined ? '' : this.selectedAutoValue?.id,
            };

            this.chatroomlist(searchLocalParams);
        }
    },
    mounted() {
        console.log(this.$router.options.history.state.back);
        this.chatroomService
            .getchartroomregiondropdown()
            .then((data) => {
                this.dropdownValues = data;
                this.autoValue = data;
                this.loading1 = false;
            })
            .catch((err) => { this.$toast.add({ severity: 'error', summary: '삭제', detail: err, life: 3000 }); });
    },



    watch: {
        showModal: function (val) {
            if (val === true) {
                this.selectedPageWiseData = this.selectedChat;
                //console.log(this.selectedPageWiseData, 'selectedPageWiseData');
                this.pagination.current_page = 1;
                this.chatroomlist({ record_count: this.pagination.pagination_limit, page: this.pagination.current_page });
                this.resetchatroom();
                }
        },

        // groupId: function (val) {
        //     if (val) {
                
        //     }
        // },
    },
    methods: {
        closeModal() {
            // this.customer1.map((item1) => {
            //     item1.room_selected = false;
            // });
            const chatRoomData = this.selectedChatrooms;
            this.selectedChatrooms = [];
            this.$emit('close', chatRoomData);
        },
        selectedData(data) {
            this.customer1.findIndex((item) => item.id === data.id);
            if (this.selectedPageWiseData.length === 9) {
                this.$toast.add({ severity: 'error', summary: '삭제', detail: '최대 9개의 채팅방을 선택할 수 있습니다.', life: 3000 });
            } else {
                this.selectedPageWiseData.push(data.id);
                this.customer1.map((item) => {
                if (item.id === data.id) {
                    item.room_selected = true;
                }
            });
            this.selectedChatrooms.push(data);
            }
            
        },
        getStatus(values) {
            return values === 'yes' ? true : false;
        },

        searchCountry(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.autoFilteredValue = [...this.autoValue];
                } else {
                    this.autoFilteredValue = this.autoValue.filter((country) => {
                        return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        chatroomlistDownloadExcel() {
            let searchParams = {
                search_key: this.search_key,
                group_type: this.group_type,
                user: this.user,
                status: this.status,
                create_start_date: this.searchdate,
                create_end_date: this.searchenddate,
                last_start_date: this.searclasthdate,
                last_end_date: this.searchlastenddate,
                is_secret: this.is_secret,
                search_type: this.search_type,
                cities: this.selectedAutoValue?.id == undefined ? '' : this.selectedAutoValue?.id,

                // startDate: start_date,
                // endDate: end_date,
            };
            this.chatroomService
                .getExcelchatroomFinal(searchParams)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'chatrromlist.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => { this.$toast.add({ severity: 'error', summary: '삭제', detail: err, life: 3000 }); });
        },

        chatroomlist(data) {
            // alert('1st');
            this.loading1 = true;
            this.submainManaggementService.professionalChatroomList(this.groupId, data).then((r) => {
                if (r != null && r.status == 200 && r.data != null && r.data.group_list != null) {
                    this.customer1 = r.data.group_list;
                    this.totalrecords = r.data.total_records;
                    this.setPagination(r.data.current_page, r.data.total_pages === 0 ? 1 : r.data.total_pages,
                        r.data.total_records);
                    // this.selectedPageWiseData.map((item) => {
                    //     this.customer1.map((item1) => {
                    //         if (item1.room_selected === false) {
                    //             if (item == item1.id) {
                    //                 item1.room_selected = true;
                    //             } else {
                    //                 item1.room_selected = false;
                    //             }

                    //         }
                    //     });
                    // });

                    this.customer1.forEach((item1) => {
                            item1.room_selected = this.selectedPageWiseData.includes(item1.id);
                    });


                    this.loading1 = false;

                }
            })
                .catch((err) => {
                    this.loading1 = false;
                    this.$toast.add({ severity: 'error', summary: '삭제', detail: err, life: 3000 });
                });
        },

        setPagination(current_page, total_pages, total_records) {
            this.pagination.current_page = current_page;
            this.pagination.total_pages = total_pages; this.pagination.total_records = total_records;
        },

        get_paginator_page(total, active) { return utils.paginate(total, active); },

        paginate(page) {
            // alert('2nd');
            this.loading1 = true;
            localStorage.setItem(
                'chatManagement',
                JSON.stringify({
                    page: page, search_key: this.search_key, group_type: this.group_type, user: this.user, status: this.status,
                    create_start_date: this.searchdate, create_end_date: this.searchenddate, last_start_date: this.searclasthdate,
                    last_end_date: this.searchlastenddate, is_secret: this.is_secret === 'yes' ? true : '', search_type: this.search_type,
                    cityName: this.selectedAutoValue.name, cities: this.selectedAutoValue?.id,
                }));

            this.submainManaggementService.professionalChatroomList(this.groupId, {
                page: page, record_count: this.pagination.pagination_limit, search_key: this.search_key,
                group_type: this.group_type, user: this.user, status: this.status, create_start_date: this.searchdate,
                create_end_date: this.searchenddate, last_start_date: this.searclasthdate, last_end_date: this.searchlastenddate,
                is_secret: this.is_secret === 'yes' ? true : '', search_type: this.search_type,
                cities: this.selectedAutoValue?.id == undefined ? '' : this.selectedAutoValue?.id,
            })
                .then((res) => {
                    this.customer1 = res.data.group_list; 
                    this.totalrecords = res.data.total_records;
                    this.pagination.current_page = page; 
                    this.customer1.forEach((item1) => {
                            item1.room_selected = this.selectedPageWiseData.includes(item1.id);
                    });
                    this.loading1 = false;
                })
                .catch((err) => { this.$toast.add({ severity: 'info', summary: '삭제', detail: err, life: 3000 }); });
        },

        resetchatroom() {
            localStorage.setItem('chatManagement', '');

            (this.search_key = ''),
                (this.group_type = ''),
                (this.user = ''),
                (this.status = ''),
                (this.date_range = ''),
                (this.lastdate_range = ''),
                (this.is_secret = ''),
                (this.search_type = ''),
                (this.cities = ''),
                this.chatroomlist({ record_count: this.pagination.pagination_limit, page: this.pagination.current_page });
            //window.location.reload();
        },

        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },
        searchchatroom() {
            // alert('3rd');
            if (this.search_key == '' || this.group_type !== '' || this.user !== '' || this.status !== '' || this.date_range !== '' || this.lastdate_range !== '' || this.is_secret !== '' || this.selectedAutoValue !== '' || this.search_type !== '') {
                console.log(this.date_range);

                if (this.date_range.at(0) == '' || this.date_range.at(0) == null || this.date_range.at(0) == undefined) {
                    this.searchdate = '';
                } else { this.searchdate = this.formatRangeDate(this.date_range.at(0)); }

                if (this.date_range.at(1) == '' || this.date_range.at(1) == null || this.date_range.at(1) == undefined) {
                    this.searchenddate = '';
                } else { this.searchenddate = this.formatRangeDate(this.date_range.at(1)) }

                if (this.lastdate_range.at(0) == '' || this.lastdate_range.at(0) == null || this.lastdate_range.at(0) == undefined) {
                    this.searclasthdate = '';
                } else { this.searclasthdate = this.formatRangeDate(this.lastdate_range.at(0)); }

                if (this.lastdate_range.at(1) == '' || this.lastdate_range.at(1) == null || this.lastdate_range.at(1) == undefined) {
                    this.searchlastenddate = '';
                } else { this.searchlastenddate = this.formatRangeDate(this.lastdate_range.at(1)); }

                localStorage.setItem(
                    'chatManagement',
                    JSON.stringify({
                        search_key: this.search_key,
                        group_type: this.group_type,
                        user: this.user,
                        status: this.status,
                        create_start_date: this.searchdate,
                        create_end_date: this.searchenddate,
                        last_start_date: this.searclasthdate,
                        last_end_date: this.searchlastenddate,
                        is_secret: this.is_secret,
                        search_type: this.search_type,
                        cityName: this.selectedAutoValue.name,
                        cities: this.selectedAutoValue?.id,
                        page: this.pagination.current_page,
                    })
                );
                let searchParams = {
                    record_count: this.pagination.pagination_limit,
                    page: 1,
                    search_key: this.search_key,
                    group_type: this.group_type,
                    user: this.user,
                    status: this.status,
                    create_start_date: this.searchdate,
                    create_end_date: this.searchenddate,
                    last_start_date: this.searclasthdate,
                    last_end_date: this.searchlastenddate,
                    is_secret: this.is_secret === 'yes' ? true : '',
                    search_type: this.search_type,
                    cities: this.selectedAutoValue?.id === undefined ? '' : this.selectedAutoValue?.id,
                    // startDate: start_date,
                    // endDate: end_date,
                };

                console.log('searchParams', searchParams);
                this.customer1 = true;
                this.submainManaggementService.professionalChatroomList(this.groupId, searchParams)
                    .then((res) => {
                        console.log('res', res);
                        this.customer1 = res.data.group_list;
                        
                        console.log('searchPost', this.customer1);
                        this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                        this.customer1.forEach((item1) => {
                            item1.room_selected = this.selectedPageWiseData.includes(item1.id);
                    });
                        this.loading1 = false;
                    })
                    .catch(() => {
                        this.customer1 = [];
                        this.loading1 = false;
                    });
            }
        },

        today() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate());
            this.lastdate_range = [firstDate, today];
            this.searchchatroom();
        },
        lastweek() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 7);
            this.lastdate_range = [firstDate, today];
            this.searchchatroom();
        },
        lastmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 31);
            this.lastdate_range = [firstDate, today];
            this.searchchatroom();
        },
        lastsixmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 182);
            this.lastdate_range = [firstDate, today];
            this.searchchatroom();
        },

        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },


        formatDate(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        calculateCustomerTotal(name) {
            let total = 0;
            if (this.customer3) {
                for (let customer of this.customer3) {
                    if (customer.representative.name === name) {
                        total++;
                    }
                }
            }

            return total;
        },
        deleteNote: function (id) {
            // console.log(id);
            var self = this;
            this.$confirm.require({
                group: 'dialog',
                header: '확인',
                message: '삭제하시겠습니까?',
                icon: 'pi pi-trash',
                acceptLabel: '확인',
                rejectLabel: ' 취소',
                accept: () => {
                    self.apartmentService
                        .delete({ id: id })
                        .then(function (response) {
                            console.log(response);
                            self.apartmentlist();
                            self.$toast.add({ severity: 'info', summary: '삭제', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                        })
                        .catch(() => {
                            self.$toast.add({ severity: 'info', summary: '삭제', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                        });
                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: '오류가 발생했습니다', detail: '취소되었습니다', life: 3000 });
                },
            });
        },
    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 300px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.modal-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
}

.modal-button {
    background-color: #3c6efb;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-button:hover {
    background-color: #2b50e3;
}

.modal-footer-new {
    display: flex;
    gap: 16px;
    justify-content: center;

}

.right {
    justify-content: flex-end;
}

.new-table-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    /* max-width: 800px; */
    width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
    /* height: 600px; */
    height: calc(100% - 120px);
}
.modal-heads{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
}
.modal-heads h4{
    font-size: 24px;
    text-align: left;
    font-weight: 600;
    margin: 0;
}

.innre-content {
    position: relative;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

.drops {
    width: 100%;
}

.drops .p-dropdown,
.p-inputtext {
    width: 100%;
}
</style>

<style lang="scss" scoped>
.p-datatable-tbody {
    .p-button {
        white-space: nowrap;
        border: 1px solid #221ED9;
    }
}

.p-fluid {
    .p-button {
        width: auto;
    }
}

p#round {
    border-radius: 12px;
    border: 1px solid black;
    width: 52px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.highlight:hover {
    border-bottom: 3px solid Blue;
}

.darkBtn {
	background: #7D7D82;
	color: #fff;
	border: none !important;
}
.darkBtn:hover, .darkBtn:active{
    background: #7D7D82;
	color: #fff;
	border: none !important;
    outline: none !important;
}

.innre-content {
    position: relative;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

.innre-content .p-field {
    display: flex;
    flex-direction: column;
}
</style>