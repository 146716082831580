<template>
    <div v-if="props.showModal" class="modal-overlay">
      <!-- <div class="modal-content"> -->
        <div class="new-table-modal-content">
          <h5>채팅방 블릿 설정</h5>
          <div class="innre-content">
            <h6>채팅방 제목</h6>
            <p>{{ details.room_name }}</p>
            <h6>채팅방 방장</h6>
            <p>{{ details.room_owner }}</p>
            <h6>블릿 타입</h6>
            <div class="drops"><Dropdown v-model="details.bullet_type" :value="details.bullet_type" optionValue="code" optionLabel="name" :options="dropdownValues" /></div>
            
            <h6>블릿명</h6>
            <div class="drops">
                <InputText maxlength="10" type="text" v-model="details.bullet_name" placeholder="블릿명을 입력해 
주세요."/>
            </div>
            <h6>수식 설정</h6>
            
            <div class="radios-group">
              <div class="p-field-radiobutton">
                <RadioButton id="option1" name="option" value="not_set" v-model="details.formula_type" />
                <label for="option1">설정 안함</label>
              </div>

              <div class="p-field-radiobutton">
                <RadioButton id="option2" name="option" value="popularity" v-model="details.formula_type" />
                <label for="option2">인기</label>
              </div>

              <div class="p-field-radiobutton">
                <RadioButton id="option3" name="option" value="recommended" v-model="details.formula_type" />
                <label for="option3">추천</label>
              </div>
            </div>
                  
          </div>
          <div class="modal-footer-new right">
            <button class="p-button p-component p-button-secondary" @click="closeModal">닫기</button> 
            <button class="modal-button p-button-primary" @click="check">저장</button> 
          </div>  
        </div>
      
    
     
    </div>
  </template>
  
  <script setup>
  import {  defineEmits, defineProps, ref, watch } from 'vue';
  import { getCurrentInstance } from 'vue';
  // import SubmainManagementService from '../../service/API/SubmainManagementService';
  const props = defineProps({
    showModal: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    bullet: {
      type: Object,
      required: false,
    },
  });
  const { proxy } = getCurrentInstance();
  
  const details = ref({});
  const dropdownValues = [
                { name: '선택안함', code: '선택안함' },
                { name: 'Type A', code: 'a' },
                { name: 'Type B', code: 'b' },
            ];
  //const submainManaggementService = new SubmainManagementService();
  const emit = defineEmits(['close']);

  const getBulletDetail = async () => {
    //try {
    //  const response = await submainManaggementService.chatroomBulletDetails(props.id);
    //  console.log(response);
      details.value = props.bullet;
      details.value.bullet_name = props.bullet.bullet_name;
      details.value.bullet_type = props.bullet.bullet_type;
      details.value.formula_type = props.bullet.formula_type;
      if (details.value.bullet_type === null || details.value.bullet_type === '') {
        details.value.bullet_type = '선택안함';
      } 
      if (details.value.bullet_name === null) {
        details.value.bullet_name = '';
      } 
      if (details.value.formula_type === null || details.value.formula_type === '') {
        details.value.formula_type = 'not_set';
      }
    //} catch (error) {
    //  console.log(error);
    //}
  };
  
  const closeModal = () => {
    emit('close');
  };
  
  const check = async() => {
    //console.log(details.value);
    // if (details.value.bullet_type === '선택안함') {
    //   setTimeout(() => {
    //     proxy.$toast.add({ severity: 'error', summary: 'Error', detail: '블릿 타입을 선택하세요', life: 3000 });
    //   }, 1000);
    // }
    if (details.value.bullet_name == '') {
      setTimeout(() => {
        proxy.$toast.add({ severity: 'error', summary: 'Error', detail: '블릿명을 입력하세요', life: 3000 });
      }, 1000);
    } else if (details.value.formula_type == null) {
      setTimeout(() => {
      proxy.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please enter formula type', life: 3000 });
      }, 1000);
    } else {
    const data = {
      component_group_chatroom_id: props.id,
      // type: 'professional',
      room_owner: details.value.room_owner,
      room_name: details.value.room_name,
      bullet_type: details.value.bullet_type === '선택안함' ? '' : details.value.bullet_type,
      bullet_name: details.value.bullet_name,
      formula_type: details.value.formula_type == 'not_set' ? '' : details.value.formula_type,
      setting_available: 1,
    };
  
    // if (details.value.bullet_type === 'a') {
    //   delete data.bullet_name;
    // }
    //try {
    //  const response = await submainManaggementService.chatroomBulletSave(data);
    //  console.log(response);
      emit('bulletSaved', data);
      //setTimeout(() => {
      //    proxy.$toast.add({ severity: 'success', summary: 'Successfull', detail: '블릿이  성공적으로 저장되었습니다.', life: 3000 });
      //  }, 1000);
    //} catch (error) {
    //  console.log(error);
    //  emit('close');
    //  setTimeout(() => {
    //      proxy.$toast.add({ severity:'error', summary:'Error', detail: '문제가 발생했습니다.', life: 3000 });
    //  }, 1000);
    //}
  }
  };

  watch(() => props.showModal, (value) => {
    if (value) {
      getBulletDetail();
    }
  });
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 300px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .modal-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .modal-button {
    background-color: #3c6efb;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-button:hover {
    background-color: #2b50e3;
  }
  .modal-footer-new{
    display: flex;
    gap: 16px;
    justify-content: center;
    
  }
  .right{
    justify-content: flex-end;
  }
  .new-table-modal-content{
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 450px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .innre-content{
    position: relative;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  .drops{
    width: 100%;
  }

  .drops .p-dropdown, .p-inputtext{
    width: 100%;
  }
  .drops .p-dropdown{
    max-width: 250px;
  }

  .radios-group{
    position: relative;
    width: 100%;
    display: flex;
    gap: 12px;
  }
  </style>